<template>
  <Modal
    mainClass="popup-report"
    contentClass="popup__content popup-report__content"
    wrapClass="popup-report__wrap"
    v-if="reviews"
    @hide="() => $emit('hide')"
    :title="`Отзывы на блогера ${instaname}`"
    :show="true"
  >
    <div class="popup-info-flex">
      <div class="popup-card" :key="review.id" v-for="review in reviews">
        <div class="popup-card-date">{{ review.date.replace('-', '.') }}</div>
        <div class="popup-card-col">
          <div class="popup-card-row">
            <p class="popup-card-name gray">Цена за подписчика</p>
            <p class="popup-card-item">{{ (review.price / review.arrival).toFixed(1) }}</p>
          </div>
          <div class="popup-card-row">
            <p class="popup-card-name gray">Приход</p>
            <p class="popup-card-item">{{ review.arrival }} подписчика</p>
          </div>
          <div class="popup-card-row">
            <p class="popup-card-name gray">Цена за рекламу</p>
            <p class="popup-card-item">{{ review.price }} ₽</p>
          </div>
          <div class="popup-card-row" v-if="review.largest_reach">
            <p class="popup-card-name gray">Охват</p>
            <p class="popup-card-item">{{ review.largest_reach }}</p>
          </div>
        </div>
        <div class="popup-card-col">
          <div class="popup-card-row">
            <p class="popup-card-name gray">Оценка</p>
            <p class="popup-card-item">
              <span class="popup-card-span">
                {{ review.rate }} <img alt="star" src="@main/assets/img/svg/star.svg" class="star"
              /></span>
            </p>
          </div>
          <div class="popup-card-row">
            <p class="popup-card-name gray">Заказчик</p>
            <a
              :href="'//www.instagram.com/' + review.customer"
              target="_blank"
              class="popup-card-item blue-text"
              >{{ review.customer }}</a
            >
          </div>
          <div class="popup-card-row">
            <p class="popup-card-name gray">Формат рекламы</p>
            <p class="popup-card-item">{{ review.ad_type_display }}</p>
          </div>
          <div class="popup-card-row" v-if="review.item">
            <p class="popup-card-name gray">Товар или услуга</p>
            <p class="popup-card-item">{{ review.item }}</p>
          </div>
          <div class="popup-card-row" v-if="review.customer_kind === 'shop'">
            <p class="popup-card-name gray">Реклама окупилась</p>
            <p class="popup-card-item">{{ review.paid_off ? 'Да' : 'Нет' }}</p>
          </div>
          <div class="popup-card-row" v-if="review.customer_tags">
            <p class="popup-card-name gray">Темы</p>
            <p class="popup-card-item">
              {{ review.customer_tags.join(', ') }}
            </p>
          </div>
        </div>
        <div class="popup-review-row" v-if="review.text">
          <p class="popup-review-gray gray">Текст отзыва</p>
          <p class="popup-card-text">{{ review.text }}</p>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapState } from 'vuex';
import reviews from '@main/api/reviews';
import Modal from './Modal.vue';

export default {
  components: {
    Modal,
  },
  data: () => ({
    reviews: null,
  }),
  computed: {
    ...mapState(['tags']),
  },
  props: {
    instaname: String,
  },
  async mounted() {
    const response = await reviews.list({ blogger: this.instaname });
    this.reviews = response.results;
  },
};
</script>

<style lang="scss" scoped>
.popup-review .form-info {
  margin-bottom: 0;
}
.popup-review-gray {
  width: 155px;
  text-align: left;
  padding-right: 10px;
  line-height: 24px;
}
.popup-review-item {
  line-height: 20px;
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  font-size: 14px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
}
.popup-review .star {
  float: right;
  margin: 0 3px;
}
.popup-delete-block {
  padding: 20px;
}
.popup-delete .popup-top {
  padding: 20px;
}
.popup-delete .form-info {
  line-height: 17px;
  margin-bottom: 0;
}
.popup-delete-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.popup-delete button {
  text-align: left;
  margin-top: 15px;
}
.popup-delete .blue-text {
  padding: 0;
}
.popup-info .pagination {
  display: none;
}
.popup-info .bloggers-content:last-child {
  border-bottom: 1px solid #d5dfe4;
}
.popup-info-container {
  max-width: 1140px;
  width: 100%;
  background: #ffffff;
  -webkit-box-shadow: 0px 3px 10px rgba(0, 73, 129, 0.1);
  box-shadow: 0px 3px 10px rgba(0, 73, 129, 0.1);
  border-radius: 10px;
  max-height: 800px;
  height: 100%;
  overflow: hidden;
}
.popup-info-block {
  padding: 0 20px;
  overflow-y: auto;
  height: calc(100% - 61px);
}
.popup-info-overflow {
  padding: 20px 0;
}
.popup-info-overflow .btn-white {
  margin: -15px auto 0;
  display: block;
}
.popup-info-overflow .popup-card-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px;
}
.popup-info-title {
  display: none;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 10px;
}
.popup-info-table {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  border-right: 1px solid #d5dfe4;
  border-left: 1px solid #d5dfe4;
  margin-bottom: 30px;
}
.popup-info-table .bloggers-content {
  background: #f8f8f8;
}
.popup-info-table .bloggers-my-content:nth-child(1),
.popup-info-table .bloggers-my-list:nth-child(1) {
  width: 114px;
}
.popup-info-table .bloggers-my-content:nth-child(2),
.popup-info-table .bloggers-my-list:nth-child(2) {
  width: 161px;
}
.popup-info-table .bloggers-my-content:nth-child(3),
.popup-info-table .bloggers-my-list:nth-child(3) {
  width: 155px;
}
.popup-info-table .bloggers-my-content:nth-child(4),
.popup-info-table .bloggers-my-list:nth-child(4) {
  width: 89px;
}
.popup-info-table .bloggers-my-content:nth-child(5),
.popup-info-table .bloggers-my-list:nth-child(5) {
  width: 90px;
}
.popup-info .form-info {
  margin-bottom: 0;
}
.popup-info-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.popup-info .popup-top {
  padding: 20px;
}
.popup-card {
  background: #ffffff !important;
  padding: 8px 10px !important;
  border: 1px solid #d5dfe4 !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  margin: 0 15px 30px 0 !important;
  width: calc(33.33% - 10px) !important;
  font-family: Rubik !important;
  font-style: normal !important;
  font-weight: normal !important;
}
.popup-card:nth-child(3n + 3) {
  margin-right: 0 !important;
}
.popup-card-row {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.popup-card-col {
  margin-bottom: 9px !important;
}
.popup-card-date {
  font-size: 12px !important;
  text-align: right !important;
  line-height: 1 !important;
  color: #6a7b83 !important;
  margin-bottom: 8px !important;
}
.popup-card-name,
.popup-card-item,
.popup-card-text {
  font-size: 14px !important;
  text-align: left !important;
  line-height: 24px !important;
}
.popup-card-name .blue-text,
.popup-card-item .blue-text,
.popup-card-text .blue-text {
  word-break: break-all !important;
}
.popup-card .star {
  float: right !important;
  height: 12px !important;
  width: 12px !important;
  margin: 5px 0 0 2px !important;
}
.popup-card-item {
  /*white-space: nowrap;*/
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 50% !important;
}
.popup-card-text {
  line-height: 20px !important;
}
.popup-card-name {
  width: 143px !important;
}
.popup-card-span {
  font-size: 14px !important;
  line-height: 24px !important;
  color: #192229 !important;
  display: -webkit-box;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}
.blue-text {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #3897f0;
  background: 0 0;
}
.gray {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #6a7b83;
}
.popup-review-gray {
  width: 155px;
  text-align: left;
  padding-right: 10px;
  line-height: 24px;
}
::v-deep .popup-report__wrap {
  max-width: 1140px !important;
}
</style>
