import apiService from '@main/api/index';
import { URL_PREFIX } from './index';

const CHANGE_LOGIN_URL = `${URL_PREFIX}/api/vp/profile/change_login/`;
const PROFILE_URL = `${URL_PREFIX}/api/vp/mutal_pr/from-stories/`;
const BLACKLIST_PROFILE_URL = `${URL_PREFIX}/api/vp/profile/blacklist/`;
const REGISTER_PROFILES_URL = `${URL_PREFIX}/api/vp/profile/register/`;

export async function register(instaname) {
  const response = await apiService.post(REGISTER_PROFILES_URL, { instaname });
  return response;
}

/**
 * Получение информации о профиле с определенным id
 */
export async function getProfile(id) {
  const response = await apiService.get(`${PROFILE_URL}${id}/`);
  return response;
}

/**
 * Запрос ВП из сторис
 */
export async function requestProfileFromStories(id, access) {
  const response = await apiService.post(PROFILE_URL, { id, access });
  return response;
}

export async function BlacklistProfile(profileId) {
  const url = BLACKLIST_PROFILE_URL;
  const response = await apiService.post(url, {
    id: profileId,
  });
  return response;
}

export async function changeProfileLogin(graphql) {
  const response = await apiService.post(CHANGE_LOGIN_URL);
  return response;
}
