<template>
  <div class="card" v-bind:class="classes" v-if="show">
    <div class="card-top">
      <div class="card-top__wrap">
        <div class="card__img">
          <img
            :src="graphql ? graphql.profile_pic_url : defaultAvatar"
            :alt="account.blogger_profile.instaname"
            :title="account.blogger_profile.instaname"
          />
        </div>
        <a
          :href="`https://www.instagram.com/${account.blogger_profile.instaname}/`"
          :title="account.blogger_profile.instaname"
          target="_blank"
          class="card__name"
        >
          {{ account.blogger_profile.instaname }}
          <span :title="`Пользователь не выложил ВП в ответ: ${account.no_mp}`" v-if="account.no_mp"
            >⚠️</span
          >
        </a>

        <span class="card__status" v-if="sendedMode">Ожидаем ответа</span>
        <span class="card__status" v-if="requestedMode">Новая заявка</span>
        <button
          type="button"
          class="card__points"
          @click.stop="() => showContext()"
          v-if="!canceledMode && !reviewMode"
        >
          <svg class="icon-points">
            <use xlink:href="@main/assets/sprite.svg#points" />
          </svg>
        </button>

        <transition name="fade">
          <div
            v-bind:class="{ act: isShowContext }"
            v-if="isShowContext"
            class="card__points-dropdown pr-helper pr-helper--btn gradient-border"
          >
            <button
              class="pr-helper__btn js-btnPopup"
              data-popup="popup-stats"
              @click="() => (showModalStatistic = true)"
              :style="{ color: !account.blogger_profile.is_premium ? 'lightgray' : 'black' }"
              :disabled="!account.blogger_profile.is_premium"
              :title="
                account.blogger_profile.is_premium
                  ? ''
                  : 'Статистика отображется только у премиум аккаунтов'
              "
            >
              Статистика блогера
            </button>

            <button
              class="pr-helper__btn js-cardBlockBtn"
              @click="
                () => {
                  block(true);
                }
              "
              v-if="!demoMode && !acceptedMode"
            >
              Больше не показывать
            </button>
            <button
              class="pr-helper__btn js-btnPopup"
              @click="
                () => {
                  makeAbuse(account.id);
                }
              "
              v-if="!demoMode"
            >
              Пожаловаться
            </button>
            <!--hr v-if="!demoMode" />
            <button
              class="pr-helper__btn js-cardBlockBtn"
              @click="simulation('create')"
              v-if="!demoMode && !requestedMode && !acceptedMode"
            >Формирует заявку</button>
            <button
              class="pr-helper__btn js-cardBlockBtn"
              @click="simulation('accept')"
              v-if="!demoMode && sendedMode"
            >Принимает заявку</button>
            <button
              class="pr-helper__btn js-cardBlockBtn"
              @click="simulation('reject')"
              v-if="!demoMode && sendedMode"
            >Отклоняет заявку</button>
            <button
              class="pr-helper__btn js-cardBlockBtn"
              @click="simulation('ignore')"
              v-if="!demoMode && sendedMode"
            >Игнорирует заявку</button>
            <button
              class="pr-helper__btn js-cardBlockBtn"
              @click="simulation('cancelMp')"
              v-if="acceptedMode"
            >Отменяет ВП</button-->
          </div>
        </transition>
      </div>

      <div class="card-top__flex">
        <p
          v-if="!account.blogger_profile.is_moderated"
          class="card-top__icon-hidden pr-helper"
          v-bind:class="{ act: moderationInfo }"
        >
          Аккаунт еще на модерации, но это не значит, что он плохой 😉
        </p>
        <div class="card-top__item">
          <p class="card-top__type">Средние охваты сторис</p>
          <div class="card-top__value">
            <span>{{
              account.blogger_profile.avg_coverage
                ? Math.round(account.blogger_profile.avg_coverage)
                : '0'
            }}</span>
            <div
              class="card-top__icon-wrap"
              @mouseenter="() => (moderationInfo = true)"
              @mouseleave="() => (moderationInfo = false)"
              v-if="account.blogger_profile.is_premium"
            >
              <svg class="icon-clock card-top__icon">
                <use xlink:href="@main/assets/sprite.svg#clock" />
              </svg>
            </div>
          </div>
        </div>
        <div class="card-top__item">
          <p class="card-top__type">Прошлые приходы подписчиков</p>
          <p class="card-top__value">
            {{
              account.mutualpr_profile
                ? account.mutualpr_profile.last_arrival_min
                  ? account.mutualpr_profile.last_arrival_min.toLocaleString().replace(',', '.')
                  : '0'
                : '0'
            }}
            —
            {{
              account.mutualpr_profile
                ? account.mutualpr_profile.last_arrival_max
                  ? account.mutualpr_profile.last_arrival_max.toLocaleString().replace(',', '.')
                  : '0'
                : '0'
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="card-content">
      <div class="card-content__flex">
        <div class="card-content__flex-item">
          <p class="card-content__flex-type">Рейтинг</p>
          <p class="card-content__flex-value">
            {{
              account.blogger_profile.reviews_stat.rate__avg
                ? account.blogger_profile.reviews_stat.rate__avg.toFixed(1)
                : 0
            }}
            <svg class="icon-star card__star-icon">
              <use xlink:href="@main/assets/sprite.svg#star" />
            </svg>
          </p>
        </div>
        <div class="card-content__flex-item">
          <p class="card-content__flex-type">Проведенных ВП</p>
          <p class="card-content__flex-value">
            {{ account.mutualpr_profile ? account.mutualpr_profile.vp_counts || 0 : 0 }}
          </p>
        </div>
        <div
          style="cursor: pointer"
          @click="
            () => {
              if (!account.blogger_profile.reviews_stat.reviews_count) {
                return;
              }
              showModalReviews = true;
            }
          "
          class="card-content__flex-item"
        >
          <p class="card-content__flex-type">Отзывов</p>
          <div
            class="card-content__flex-value"
            v-if="account.blogger_profile.reviews_stat.reviews_count === 0"
          >
            {{ account.blogger_profile.reviews_stat.reviews_count }}
          </div>
          <div class="card-content__flex-value card-content__flex-value--link" v-else>
            <a>{{ account.blogger_profile.reviews_stat.reviews_count }}</a>
          </div>
        </div>
      </div>
      <div class="card-content__wrap" v-if="account.blogger_profile.is_premium">
        <div class="card-content__wrap-item" v-if="account.blogger_profile.main_audience">
          <p class="card-content__wrap-type">Преобладающий возраст ↓</p>
          <p class="card-content__wrap-value" v-if="account.blogger_profile.main_audience">
            {{ currentAudience.name }} —
            {{ account.blogger_profile.main_audience.age_group_percent }}%
          </p>
        </div>
        <div class="card-content__wrap-item" v-if="account.blogger_profile.main_country">
          <p class="card-content__wrap-type">Преобладающая страна ↓</p>
          <p class="card-content__wrap-value" v-if="account.blogger_profile.main_country">
            {{ account.blogger_profile.main_country.country.name }} —
            {{ account.blogger_profile.main_country.coverage }}%
          </p>
        </div>
      </div>
      <div class="card-content__theses js-btnPopup" data-popup="popup-theses">
        <span class="card-content__theses-text is-desktop" @click="() => (showTheses = true)"
          >Открыть тезисы</span
        >
        <span
          class="card-content__theses-text to-desktop"
          @click="() => (showMobileTheses = !showMobileTheses)"
          >Открыть тезисы</span
        >
        <span
          class="card-content__theses-icon to-desktop"
          @click="() => (showMobileTheses = !showMobileTheses)"
        ></span>

        <p class="to-desktop" v-if="showMobileTheses">
          {{
            account.blogger_profile.theses
              ? account.blogger_profile.theses
              : 'Пользователь еще не ввел тезисы'
          }}
        </p>
      </div>
      <p class="card-content__theme" v-if="themes.length > 0">Темы: {{ themes }}</p>
    </div>
    <div class="card-footer card-footer--alone" v-if="demoMode || canceledMode">
      <button
        v-if="!storiesRequest && !canceledMode"
        type="button"
        class="btn--orange card-footer__btn js-cardBtnGet"
        @click="showingAuthModal(true)"
      >
        Хочу ВП
      </button>
      <button
        v-else-if="storiesRequest"
        type="button"
        class="btn--orange card-footer__btn js-cardBtnGet"
        @click="sendStoriesRequest"
        :disabled="sendedMode"
      >
        Хочу ВП
      </button>
      <button
        v-else-if="canceledMode"
        type="button"
        class="btn--border card-footer__btn js-cardBtnReject"
        @click="restore"
      >
        Восстановить
      </button>
    </div>
    <div class="card-footer" v-if="reviewMode">
      <button
        type="button"
        class="btn--border card-footer__btn js-cardBtnReject"
        @click="() => $emit('hide')"
      >
        Удалить
      </button>
      <button
        type="button"
        class="btn--orange card-footer__btn js-cardBtnGet"
        @click="() => $emit('send-review', this.account)"
      >
        Оставить отзыв
      </button>
    </div>
    <div class="card-footer" v-if="searchMode">
      <button type="button" class="btn--border card-footer__btn js-cardBtnReject" @click="reject">
        Отклонить
      </button>
      <button
        type="button"
        class="btn--orange card-footer__btn js-cardBtnGet"
        @click="() => request()"
        :disabled="sendedMode"
      >
        Хочу ВП
      </button>
    </div>
    <div class="card-footer" v-if="sendedMode">
      <button
        type="button"
        class="btn--border card-footer__btn js-cardBtnReject reject__standalone-btn"
        @click="() => reject()"
        :title="getHours() >= stopTime ? 'Вы не можете отменить заявку после 17:00' : ''"
        :disabled="getHours() >= stopTime"
      >
        Отменить заявку
      </button>
    </div>
    <div class="card-footer" v-if="requestedMode">
      <button type="button" class="btn--border card-footer__btn js-cardBtnReject" @click="reject">
        Отклонить
      </button>
      <button
        type="button"
        class="btn--orange card-footer__btn js-cardBtnGet"
        :disabled="acceptDisabled"
        @click="approve"
      >
        Принять
      </button>
    </div>
    <div class="card-footer" v-if="acceptedMode">
      <button
        type="button"
        class="btn--border pr-events__btn pr-events__btn--small"
        @click="cancelMp()"
      >
        Отменить
      </button>
      <b-button
        type="button"
        variant="warning"
        class="btn--orange pr-events__btn card-footer__btn partner-write__btn"
        @click="showPartnerModal = true"
      >
        Написать партнеру
      </b-button>
    </div>

    <div class="card-popup card-popup__blocked" v-bind:class="{ act: isBlocked }">
      <p class="card-popup__blocked-text">Этот аккаунт больше не будет Вам показываться</p>
      <button class="btn--border card-popup__blocked-btn" @click="block(false)">
        Отмена {{ blockTimeOutValue }}
      </button>
    </div>

    <div class="card-popup card-popup__get" :class="{ act: sendMode }">
      <div class="card-popup__get-content">
        <svg class="icon-done card-popup__get-icon">
          <use xlink:href="@mp/assets/sprite.svg#done" />
        </svg>

        <p class="card-popup__get-title">Заявка отправлена</p>
        <div class="card-popup__get-text">
          Рекомендуем отправить несколько заявок разным блогерам, чтобы повысить вероятность найти
          ВП на завтра.
        </div>
        <button
          class="btn--border card-popup__get-btn-cancel"
          @click="() => (sendMode = false)"
          :disabled="okDisabled"
        >
          Отменить
        </button>
        <button
          class="btn--orange pr-events__btn btn_ok"
          @click="() => (sendMode = false)"
          :disabled="okDisabled"
        >
          Окей
        </button>
      </div>
    </div>

    <div class="card-popup__reject card-popup" v-bind:class="{ act: cancelMode }">
      <button class="btn--border card-popup__reject-back" @click="cancelReject">
        Я передумал(-а) {{ timeOutValue }}
      </button>
      <div class="btn--border card-popup__reject-info">
        <svg class="icon-cross card-popup__reject-icon">
          <use xlink:href="@mp/assets/sprite.svg#cross" />
        </svg>
        <span>Заявка отменена</span>
      </div>
    </div>
    <div class="card-popup card-popup__cancel" v-bind:class="{ act: rejectMP }">
      <p class="card-popup__cancel-title">Вы отменяете согласованное ВП</p>
      <p class="card-popup__cancel-text">
        Партнер в этом случае может снять с вас 1 жизнь. Когда жизни закончатся вы не сможете больше
        воспользоваться ВП на завтра до начала следующего месяца.
      </p>
      <p class="card-popup__cancel-strike">
        У вас осталось
        <span>{{ currentUser ? currentUser.strikes : 3 }}</span> жизни.
      </p>
      <button
        type="button"
        class="btn--border card-popup__cancel-btn"
        @click="() => stopCancelMp()"
      >
        Назад
      </button>
      <span class="card-popup__cancel-timer">До отмены осталось: {{ diffRejectTime }}</span>
    </div>

    <Modal
      :show="showTheses"
      @hide="() => (showTheses = false)"
      title="Тезисы"
      wrapClass="popup-theses__wrap"
    >
      <p class="popup-theses__text">
        {{
          !!account.blogger_profile.theses
            ? account.blogger_profile.theses
            : 'Пользователь еще не указал тезисы'
        }}
      </p>
    </Modal>
    <modal-statistic
      :show="showModalStatistic"
      @hide="() => (showModalStatistic = false)"
      :account="account"
    />
    <reviews-modal
      v-if="showModalReviews"
      :instaname="account.blogger_profile.instaname"
      @hide="() => (showModalReviews = false)"
    />
    <read-partner-modal
      v-if="typeof currentUser !== 'undefined'"
      :show="showPartnerModal"
      @hide="() => (showPartnerModal = false)"
      :author="currentUser"
      :partner="account"
    />
  </div>
</template>

<script>
import ReviewsModal from '@main/components/reusable/modals/ReviewsModal.vue';
import { requestProfileFromStories, BlacklistProfile } from '@mp/api/profile';
import { restore } from '@mp/api/search';
import { mapMutations, mapState } from 'vuex';
import db from '@main/db/idb';
import { RunSim } from '@mp/api/simulation';
import instagram from '@main/api/instagram';
import Modal from '@main/components/reusable/modals/Modal.vue';
import alertGreyIcon from '@main/assets/img/svg/allert_grey.svg';
import ModalStatistic from './ModalStatistic.vue';
import ReadPartnerModal from './ReadPartnerModal.vue';

import avatar from './default.png';

export default {
  props: {
    account: {
      type: Object,
      require: true,
    },
    mode: {
      type: String,
      default: 'DEMO',
    },
    rejectTime: {
      type: Date,
      default: null,
    },
    storiesRequest: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Modal,
    ModalStatistic,
    ReadPartnerModal,
    ReviewsModal,
  },
  data: () => ({
    okDisabled: false,
    showTheses: false,
    moderationInfo: false,
    showMobileTheses: false,
    showModalStatistic: false,
    showModalReviews: false,
    isBlocked: false,
    show: false,
    mid: 300,
    sendMode: false,
    cancelMode: false,
    stopTime: 17,
    defRejectTimeout: 0,
    timeOutValue: 5,
    blockTimeOutValue: 5,
    showPartnerModal: false,
    rejectTimeoutValue: 180000,
    rejectTimeout: 0,
    rejectMP: false,
    acceptDisabled: false,
    graphql: null,
    audience: [
      {
        id: 0,
        name: 'Не определена',
      },
      {
        id: 1,
        name: '13-17',
      },
      {
        id: 2,
        name: '18-24',
      },
      {
        id: 3,
        name: '25-34',
      },
      {
        id: 4,
        name: '35-44',
      },
      {
        id: 5,
        name: '45-54',
      },
      {
        id: 6,
        name: '55-64',
      },
      {
        id: 7,
        name: '65+',
      },
    ],
  }),
  computed: {
    ...mapState(['currentUser']),
    ...mapState('mutualpr', ['currentContext']),
    classes() {
      return {
        'card--prem': this.account.blogger_profile.is_premium,
        canceled: this.sendedMode,
        progress: this.requestedMode,
        accept: this.acceptedMode,
        review: this.reviewMode,
      };
    },
    currentAudience() {
      return this.audience.filter(
        (val) => this.currentUser.blogger_profile.main_audience.age_group === val.id
      )[0];
    },
    defaultAvatar() {
      return avatar;
    },
    themes() {
      if (!this.account.blogger_profile.tags.length) {
        return '';
      }
      return this.account.blogger_profile.tags.map((theme) => theme.name).join(', ');
    },
    isShowContext() {
      return this.currentContext === `context_${this.account.id}`;
    },
    demoMode() {
      // демо режим (для главной страницы)
      return this.mode.toLowerCase() === 'demo';
    },
    sendedMode() {
      // заявка отправлена
      return this.mode.toLowerCase() === 'sended';
    },
    searchMode() {
      // поиск (обычная для выдачи)
      return this.mode.toLowerCase() === 'search';
    },
    canceledMode() {
      // отменена
      return this.mode.toLowerCase() === 'canceled';
    },
    requestedMode() {
      // входящая заявка
      return this.mode.toLowerCase() === 'requested';
    },
    acceptedMode() {
      // принятая заявка (ВП на завтра сформирован)
      return this.mode.toLowerCase() === 'accepted';
    },
    // прошлая заявка
    reviewMode() {
      return this.mode.toLowerCase() === 'review';
    },
    diffRejectTime() {
      return this.millisToMinutesAndSeconds(this.rejectTimeoutValue);
    },
  },
  methods: {
    ...mapMutations('mutualpr', ['showingAuthModal', 'setCurrentContext', 'makeAbuse']),
    ...mapMutations('notifications', ['showMessage', 'setWindow']),
    showContext() {
      if (this.isShowContext) {
        this.setCurrentContext('');
      } else {
        this.setCurrentContext(`context_${this.account.id}`);
      }
    },
    reject() {
      this.cancelMode = true;
      this.defRejectTimeout = setInterval(() => {
        if (this.timeOutValue === 0) {
          this.timeOutValue = 5;
          clearInterval(this.defRejectTimeout);
          this.$emit('reject', this.account.id);
          this.hide(true);
        }
        this.timeOutValue -= 1;
      }, 1000);
    },
    async restore() {
      try {
        await restore(this.account.id);
      } catch (e) {
        console.log(e);
      }
    },
    cancelReject() {
      this.cancelMode = false;
      clearInterval(this.defRejectTimeout);
    },
    request() {
      // this.hide(true);
      console.log(this.account.id);
      this.sendMode = true;
      this.okDisabled = true;
      this.$emit('request', this.account.id);
      this.okDisabled = false;
    },
    cancelRequest() {
      this.sendMode = false;
      this.$emit('cancelRequest', this.account.id);
    },
    approve() {
      this.acceptDisabled = true;
      this.$emit('approve', this.account.id);
      this.hide();
    },
    cancelMp() {
      this.rejectMP = true;
      const fn = () => {
        this.rejectTimeoutValue -= 1000;
        if (this.rejectTimeoutValue <= 0) {
          this.$emit('cancel-mp', this.account.id);
          clearInterval(this.rejectTimeout);
          this.hide(true);
        }
      };
      fn();
      this.rejectTimeout = setInterval(fn, 1000);
    },

    stopCancelMp() {
      clearInterval(this.rejectTimeout);
      this.rejectTimeoutValue = 180000;
      this.rejectMP = false;
    },

    // eslint-disable-next-line no-unused-vars
    async simulation(mode) {
      const response = await RunSim(mode, this.account.id);
      if (response.error) {
        this.mid += 1;
        this.showMessage({
          id: this.mid,
          type: 'basic',
          title: 'Запрос отправлен',
          icon: 1,
        });
      }
    },
    hide(now) {
      // todo: учесть отмену
      if (now) {
        this.cancelMode = false;
        this.isBlocked = false;
        this.show = false;
        this.sendMode = false;
      }
      setTimeout(() => {
        this.cancelMode = false;
        this.isBlocked = false;
        this.show = false;
        this.sendMode = false;
      }, 5000);
    },
    block(bool) {
      if (bool) {
        this.blockMode = true;
        this.isBlocked = true;
        this.blockTimeOutValue = 5;
        const tm = () => {
          if (this.isBlocked && this.blockTimeOutValue <= 0) {
            BlacklistProfile(this.account.mutualpr_profile.id);
            this.showMessage({
              id: 668,
              type: 'basic',
              title: `Пользователь ${this.account.blogger_profile.instaname} больше не будет появляться в ваших выдачах`,
              icon: 1,
            });
            clearInterval(this.blockTimeout);
            this.blockTimeOutValue = 5;
            this.cancelMode = false;
            this.blockMode = false;
            this.$emit('reject', this.account.id);
            return;
          }
          this.blockTimeOutValue -= 1;
        };
        this.blockTimeout = setInterval(tm, 1000);
      } else {
        clearInterval(this.blockTimeout);
        this.blockTimeOutValue = 5;
        this.cancelMode = false;
        this.isBlocked = false;
      }
    },
    getHours() {
      const date = new Date();
      return date.getUTCHours() + 3;
    },
    millisToMinutesAndSeconds(millis) {
      const minutes = Math.floor(millis / 60000);
      const seconds = ((millis % 60000) / 1000).toFixed(0);
      if (minutes <= 0 || seconds <= 0) {
        return '0:0';
      }
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
    async sendStoriesRequest() {
      if (!this.currentUser) {
        this.showingAuthModal(true);
        this.$emit('stories-request-fail');
        return;
      }
      try {
        const response = await requestProfileFromStories(
          this.$route.query.profile,
          this.$route.query.access
        );
        if (response.success) {
          this.showMessage({
            id: 500,
            type: 'basic',
            title: 'Запрос успешно отправлен',
            icon: 1,
          });
          this.$emit('stories-request');
        } else {
          this.showMessage({
            id: 500,
            type: 'basic',
            title: `Ошибка: ${response.reason}`,
            icon: 3,
          });
          this.$emit('stories-request-fail');
        }
        this.$router.push('/mutualpr/');
      } catch (e) {
        this.setWindow({
          text:
            'Произошла ошибка при попытке запроса ВП из сторис. Побробуйте позже или свяжитесь с технической поддержкой - help@easyprbot',
          iconPath: alertGreyIcon,
        });
      }
    },
  },
  mounted() {
    instagram
      .getAccountGraphql(this.account.blogger_profile.instaname)
      .then((res) => {
        this.graphql = res.graphql.user;
      })
      .catch((e) => {
        this.graphql = null;
      });
    this.show = true;
  },
};
</script>

<style scoped>
.theses-content {
  padding: 1em;
}
.card-footer__btn {
  width: calc(50% - 5px) !important;
  max-width: 147px !important;
}
.card-footer {
  background: transparent;
}
.card__status {
  font-size: 12.5px !important;
}
.icon-star {
  height: 13px;
  width: 13px;
}
.btn_ok {
  max-width: 60px;
  margin-left: 2em;
}
.link {
  cursor: pointer;
}
.partner-write__btn {
  width: 220px !important;
}
.reject__standalone-btn {
  width: 200px !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
