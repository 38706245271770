<template lang="html">
  <div class="pr-events--past" v-if="mutualPrs && mutualPrs.length > 0">
    <div class="pr-events__title-wrap">
      <h2 class="pr-events__title">
        Оставьте отзыв о ВП <span>{{ `${step}/${steps}` }}</span>
      </h2>
      <div class="pr-events__arrows" v-if="mutualPrs.length > 1">
        <button
          type="button"
          @click="
            () => {
              if (step > 1) {
                step -= 1;
              }
            }
          "
          class="pr-events__slide-btn"
          :disabled="step <= 1"
        >
          <svg class="pr-events__arrow pr-events__arrow-revers">
            <use xlink:href="@mp/assets/sprite.svg#arrow"></use>
          </svg>
        </button>
        <button
          type="button"
          @click="
            () => {
              if (step < steps) {
                step += 1;
              }
            }
          "
          class="pr-events__slide-btn"
          :disabled="step >= steps.length"
        >
          <svg class="pr-events__arrow">
            <use xlink:href="@mp/assets/sprite.svg#arrow"></use>
          </svg>
        </button>
      </div>
    </div>
    <div class="pr-events__wrap">
      <Card
        mode="review"
        v-for="(i, idx) in mutualPrs"
        :style="{ display: idx + 1 === step ? '' : 'none' }"
        :key="`${i.customer.id === currentUser.id ? i.executor.id : i.customer.id}-mpPast`"
        :account="i.customer.id === currentUser.id ? i.executor : i.customer"
        @send-review="sendReview($event, idx)"
        @hide="hidePast(i.id)"
      />
    </div>
  </div>
</template>

<script>
import MutualPRMixin from '@main/mixins/mutualpr/MutualPRMixin';
import * as searchApi from '@mp/api/search.js';

export default {
  data: () => ({
    step: 1,
  }),
  mixins: [MutualPRMixin],
  props: {
    mutualPrs: Array,
  },
  computed: {
    steps() {
      return this.mutualPrs.length;
    },
  },
  methods: {
    sendReview(account, idx) {
      const prObject = this.mutualPrs[idx];
      const date = +new Date();
      this.$router.push({
        name: 'let-review-filled',
        params: { advertiser: prObject.executor.login },
        query: { customer: prObject.customer.login, date, mutual: 1 },
      });
    },
    async hidePast(id) {
      await searchApi.hidePastMutalPr(id);
    },
  },
};
</script>

<style lang="css" scoped></style>
